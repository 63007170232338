<template>
  <div class="footer mt-16">
    <v-footer padless>
      <v-card class="flex" flat tile>
        <v-card class="secondary px-2 py-5">
          <p class="body-1 text-center white--text mb-0 mx-1">
            Copyright ©SoftiDoc, All rights reserved. 41 Waterberry, Olympus
            Country Estate, Olympus, Pretoria East, 0081
          </p>
        </v-card>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>

<style lang="scss" scoped>
.footer {
  .bg-footer {
    background: rgba(13, 69, 214, 0.05);
  }

  .bg-transparent {
    background: transparent;
  }
}
</style>
