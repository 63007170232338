<template>
  <v-container fluid>
    <div class="dashboard-layout">
      <TheDashboardHeader />

      <Sidebar v-if="!noSidebar" />

      <div class="py-5">
        <router-view />
      </div>
      <TheFooter />

      <div v-if="aiModel.dialogAiModel" class="ai-model">
        <v-btn type="button" class="btn btn-primary">
          Checking Ai Model
          <countdown
            ref="countdown"
            :time="aiModel.timeLeft"
            @end="restartTime()"
          >
            <template slot-scope="props">
              {{ props.minutes }}:{{ props.seconds }}
            </template>
          </countdown>
          later
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import TheDashboardHeader from "@/components/elements/dashboard/TheDashboardHeader";
import TheFooter from "@/components/elements/commons/TheFooter";
import Sidebar from "@/components/elements/dashboard/Sidebar";

import { mapMutations, mapState } from "vuex";

export default {
  props: {
    noSidebar: Boolean,
  },

  data() {
    return {
      time: 0,
      aiModelObject: {},
    };
  },

  components: {
    TheDashboardHeader,
    TheFooter,
    Sidebar,
  },

  computed: {
    ...mapState({ aiModel: "aiModel" }),

    // showVerifyEmail
    showVerifyEmail() {
      return this.$store.getters['auth/getShowVerifyEmail'];
    }
  },

  created() {
    // showVerifyEmail
    if(this.showVerifyEmail){      
      this.$router.push({ name: "RequestVerifyEmail" });
    }

    if (this.aiModel.dialogAiModel) {
      this.countDownTimer();
    }
  },

  watch: {
    "aiModel.dialogAiModel"() {
      if (this.aiModel.dialogAiModel) {
        this.countDownTimer();
      }
    },

    // showVerifyEmail
    showVerifyEmail(val){
      // showVerifyEmail
      if(val){      
        this.$router.push({ name: "RequestVerifyEmail" });
      }
    },
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
      resetAiModelState: "aiModel/resetState",

      setDialogAiModel: "aiModel/setDialogAiModel",
      setTotalTime: "aiModel/setTotalTime",
      setAiModelId: "aiModel/setAiModelId",
      setTimeLeft: "aiModel/setTimeLeft",
      setTime: "aiModel/setTime",

      setCountDown: "aiModel/setCountDown",
    }),

    restartTime() {
      // console.log("", "this.$refs.countdown.start");
      // this.$refs.countdown.restart();
    },

    // countDownTimer
    countDownTimer() {
      if (this.aiModel.countDown > 0) {
        var duration = 1;

        setTimeout(() => {
          this.setCountDown(this.aiModel.countDown - duration);
          this.setTimeLeft(this.aiModel.timeLeft - 1000);

          if (this.aiModel.countDown % 30 == 0) {
            // getApiAiModelById
            this.getApiAiModelById(this.aiModel.aiModelId);
          }

          this.countDownTimer();
        }, duration * 1000);
      } else {
        if (this.aiModelObject == null) {
          //
        } else if (this.aiModelObject.error_message != null) {
          //
        } else if (this.aiModelObject.status != "Ready To Use") {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Ai Model Is Not Ready To Use Please Check The Model Later",
          });
        }

        // resetAiModelState
        this.resetAiModelState();
      }
    },

    // getApiAiModelById
    async getApiAiModelById(id) {
      console.log("getApiAiModelById");

      if (id > 0) {
        const res = await this.$helpers.getApiData(`ai-models/${id}`);

        if (res.status == 200) {
          this.aiModelObject = res.data;

          if (this.aiModelObject.status == "Ready To Use") {
            this.resetAiModelState();

            this.$swal({
              icon: "success",
              title: "Ai Model Ready To Use",
              text: "Ai Model " + this.aiModelObject.name + " Ready To Use",
            });
          } else if (this.aiModelObject.error_message != null) {
            this.resetAiModelState();

            this.$swal({
              icon: "error",
              title: "Error For Ai Model " + this.aiModelObject.name,
              text: this.aiModelObject.error_message,
            });
          } else {
            //
          }
        } else if (res.status == 404) {
          this.resetAiModelState();

          this.aiModelObject = null;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ai-model {
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
