<template>
  <div class="sidebar">
    <v-navigation-drawer
      v-model="sidebarDrawer"
      :mini-variant.sync="dashboardSidebarMini"
      :expand-on-hover="dashboardSidebarMini"
      class="secondary"
      dark
      app
      v-bind="$attrs"
    >
      <template v-slot:prepend>
        <v-card flat color="transparent" height="70px">
          <v-list v-if="dashboardSidebarMini">
            <v-list-item :to="{ name: 'Login' }">
              <v-list-item-icon>
                <v-icon> mdi-home </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Home</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <div class="pa-4" v-else>
            <v-btn class="ml-9" :to="{ name: 'Login' }" icon>
              <v-img
                alt="logo"
                contain
                class="logo mb-n2"
                src="@/assets/img/logo/logo.svg"
                max-width="114"
                max-height="49"
              />
            </v-btn>
          </div>
        </v-card>
      </template>

      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          active-class="white secondary--text"
          :to="item.to"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Inbox -->
        <v-list-item
          v-if="$can('document_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardInbox' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-inbox-arrow-down </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Inbox </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Addresses -->
        <v-list-item
          v-if="$can('address_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardAddresses' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-marker </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Addresses </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Ai Models -->
        <v-list-item
          v-if="$can('ai_model_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardAiModels' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-sync </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Ai Models </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Api Keys -->
        <v-list-item
          v-if="$can('api_key_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardApiKeys' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-lock-open </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Api Keys </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Category Columns -->
        <v-list-item
          v-if="$can('category_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardCategoryColumns' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-arrow-down-outline </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Category Columns </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Categories -->
        <v-list-item
          v-if="$can('category_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardCategories' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-arrow-up </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Categories </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Companies -->
        <v-list-item
          v-if="$can('company_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardCompanies' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-marker </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Companies </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Contacts -->
        <v-list-item
          v-if="$can('contact_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardContacts' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-phone-log </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Contacts </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Credits -->
        <v-list-item
          v-if="$can('credit_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardCredits' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-account-cash </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Credits </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Credit Transactions -->
        <v-list-item
          v-if="$can('credit_transaction_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardCreditTransactions' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-account-credit-card </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Credit Transactions </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Documents -->
        <v-list-item
          v-if="$can('document_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardDocuments' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-file </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Documents </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Document Lines -->
        <v-list-item
          v-if="$can('document_line_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardDocumentLines' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-file-outline </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Document Lines </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Images -->
        <v-list-item
          v-if="$can('image_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardImages' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-image </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Images </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Invites -->
        <v-list-item
          v-if="$can('invite_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardInvites' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-multiple-plus </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Invites </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Password Resets -->
        <v-list-item
          v-if="$can('password_reset_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardPasswordResets' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-key </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Password Resets </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Permissions -->
        <v-list-item
          v-if="$can('permission_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardPermissions' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-cog-outline </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Permissions </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Roles -->
        <v-list-item
          v-if="$can('role_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardRoles' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-cog </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Roles </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Service Categories -->
        <v-list-item
          v-if="$can('service_category_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardServiceCategories' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-remove </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Service Categories </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Services -->
        <v-list-item
          v-if="$can('service_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardServices' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-remove-outline </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Services </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Service Details -->
        <v-list-item
          v-if="$can('service_detail_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardServiceDetails' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-text-outline </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Service Details </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Users -->
        <v-list-item
          v-if="$can('user_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardUsers' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-account </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Users </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Vendors -->
        <!-- <v-list-item
          v-if="$can('vendor_access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardVendors' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-folder-open-outline </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Vendors </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <!-- Profile -->
        <v-list-item
          v-if="$can('access')"
          active-class="white secondary--text"
          :to="{ name: 'DashboardProfile' }"
          link
        >
          <v-list-item-icon>
            <v-icon> mdi-account </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> Profile </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list v-if="dashboardSidebarMini">
          <v-list-item :to="{ name: 'Login' }">
            <v-list-item-icon>
              <v-icon> mdi-logout </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div class="pa-2" v-else>
          <!-- :to="{ name: 'Login' }" -->
          <v-btn @click="logOut()" class="primary" block> Logout </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      drawer: true,
      sidebarMini: true,
      sidebarDrawer: true,
      // items: [
      //   { title: 'Home', icon: 'mdi-home-city' },
      //   { title: 'My Account', icon: 'mdi-account' },
      //   { title: 'Users', icon: 'mdi-account-group-outline' },
      // ],
      mini: false,
      items: [
        // {
        //   title: "Dashboard",
        //   icon: "mdi-view-dashboard",
        //   to: { name: "DashboardHome" },
        //   permission: "access",
        // },
        // {
        //   title: "Inbox",
        //   icon: "mdi-inbox-arrow-down",
        //   to: { name: "DashboardInbox" },
        //   permission: "access",
        // },
        // {
        //   title: "API DOCS",
        //   icon: "mdi-file-document-multiple-outline",
        //   // to: { name: "Home" },
        //   permission: "access",
        // },
        // {
        //   title: "LENS",
        //   icon: "mdi-cellphone",
        //   // to: { name: "Home" },
        //   permission: "access",
        // },
        // {
        //   title: "EXPORTS",
        //   icon: "mdi-file-export-outline",
        //   // to: { name: "Home" },
        //   permission: "access",
        // },
        // {
        //   title: "DATA TRANSFORMATIONS",
        //   icon: "mdi-share-all",
        //   // to: { name: "Home" },
        //   permission: "access",
        // },
        // {
        //   title: "SETTINGS",
        //   icon: "mdi-cog",
        //   // to: { name: "Home" },
        //   permission: "access",
        // },
        // {
        //   title: "ADD TEAM",
        //   icon: "mdi-account-plus",
        //   // to: { name: "Home" },
        //   permission: "access",
        // },
        // {
        //   title: "BILLING",
        //   icon: "mdi-cash-multiple ",
        //   // to: { name: "Home" },
        //   permission: "access",
        // },
      ],
    };
  },

  computed: {
    ...mapState(["dashboardSidebarMini", "dashboardSidebarDrawer"]),
  },

  created() {
    this.sidebarMini = this.dashboardSidebarMini;
    this.sidebarDrawer = this.dashboardSidebarDrawer;
  },

  watch: {
    // sidebarMini
    sidebarMini(val) {
      this.setDashboardSidebarMini(val);
    },

    // sidebarDrawer
    sidebarDrawer(val) {
      this.setDashboardSidebarDrawer(val);
    },
  },

  methods: {
    ...mapActions({
      logOutUser: "auth/logOutUser",
    }),

    ...mapMutations({
      setDashboardSidebarDrawer: "setDashboardSidebarDrawer",
      setDashboardSidebarMini: "setDashboardSidebarMini",

      resetAuthState: "auth/resetState",
    }),

    logOut() {
      this.resetAuthState();

      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  top: 80px;
  bottom: 0;
  z-index: 4;

  @media only screen and (max-width: 600px) {
    margin-top: 80px;
  }
}
</style>
