<template>
  <div class="nav-bar">
    <v-card
      class="secondary d-flex align-center px-2 mt-n3 ml-md-n3 mr-md-n6 pa-md-5"
      min-height="50"
      max-height="85"
      width="100%"
      flat
      tile
    >
      <v-card class="d-flex align-center transparent" width="200" flat>
        <router-link
          :to="{ name: 'DashboardInbox' }"
          class="heading-h5 white--text text-decoration-none"
        >
          Dashboard
        </router-link>

        <v-spacer />

        <v-btn
          class="hidden-md-and-down"
          size="36"
          color="primary"
          icon
          @click="changeDashboardSidebarMini()"
        >
          <v-icon size="36" color="primary" v-if="dashboardSidebarMini">
            mdi-view-quilt
          </v-icon>

          <v-icon size="36" color="primary" v-else> mdi-dots-vertical </v-icon>
          <!-- <v-icon size="36" color="primary"> mdi-dots-vertical </v-icon> -->
        </v-btn>

        <div v-if="auth.user.company_credit == null" class="hidden-md-and-down">
          <v-btn @click="createMyCompanyCredit()" color="primary" dark>
            Show Credits
          </v-btn>
        </div>

        <div v-if="auth.user.company_credit != null" class="hidden-md-and-down">
          <h5 class="heading-h5 white--text text-decoration-none text-no-wrap">
            Available Credits: {{ auth.user.company_credit.available }}
          </h5>
        </div>

        <div
          v-if="auth.user.company_credit != null"
          class="ml-4 hidden-md-and-down"
        >
          <v-btn @click="dialogBuyCredits = true" color="primary" dark>
            Buy Credits
          </v-btn>
        </div>
      </v-card>

      <v-spacer />

      <div class="hidden-md-and-down">
        <div class="d-flex align-center">
          <!-- Verify Email -->
          <div
            v-if="showVerifyEmail"
            class="mr-4 hidden-md-and-down"
          >
            <v-btn @click="verifyEmail()" color="primary" dark>
              Verify Email
            </v-btn>
          </div>

          <h5
            v-if="auth.user.company"
            class="heading-h5 white--text text-decoration-none mr-2"
          >
            {{ auth.user.company.name }}
          </h5>

          <v-menu offset-y open-on-click open-on-focus open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon color="white" large> mdi-account </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item :to="{ name: 'DashboardProfile' }" link>
                <v-icon> mdi-account-plus </v-icon>
                <h5 class="pl-2">Profile</h5>
              </v-list-item>
              <v-list-item link>
                <!-- :to="{ name: 'Login' }" -->
                <v-btn @click="logOut()" class="primary" block>
                  <v-icon>mdi-logout </v-icon> Logout
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <v-btn class="hidden-lg-and-up" icon @click="drawer = true">
        <v-icon size="36" color="white" dark right> mdi-menu </v-icon>
      </v-btn>
    </v-card>

    <v-navigation-drawer
      app
      v-model="drawer"
      absolute
      temporary
      class="hidden-md-and-up"
    >
      <v-list color="white" height="100%" nav dense>
        <v-list-item-group v-model="group" active-class="white--text white">
          <v-list-item color="secondary" class="d-flex justify-end my-0">
            <v-icon color="white" @click="drawer = !drawer">mdi-close</v-icon>
          </v-list-item>

          <v-list-item active-class="secondary" :to="{ name: 'dashboard' }">
            <v-img
              alt="logo"
              contain
              class="logo"
              src="@/assets/img/logo/logo.svg"
              max-width="114"
            />
          </v-list-item>

          <!-- Show Credits -->
          <v-list-item
            v-if="auth.user.company_credit == null"
            active-class="white secondary--text"
            @click="createMyCompanyCredit()"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-credit-card-settings-outline </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Show Credits </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Available Credits -->
          <v-list-item
            v-if="auth.user.company_credit != null"
            active-class="white secondary--text"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-star-outline </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                Available Credits: {{ auth.user.company_credit.available }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Buy Credits -->
          <v-list-item
            v-if="auth.user.company_credit != null"
            active-class="white secondary--text"
            @click="dialogBuyCredits = true"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-credit-card-settings-outline </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Buy Credits </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Inbox -->
          <v-list-item
            v-if="$can('document_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardInbox' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-inbox-arrow-down </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Inbox </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Addresses -->
          <v-list-item
            v-if="$can('address_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardAddresses' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-marker </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Addresses </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Ai Models -->
          <v-list-item
            v-if="$can('ai_model_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardAiModels' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-sync </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Ai Models </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Api Keys -->
          <v-list-item
            v-if="$can('api_key_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardApiKeys' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-lock-open </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Api Keys </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Category Columns -->
          <v-list-item
            v-if="$can('category_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardCategoryColumns' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-arrow-down-outline </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Category Columns </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Categories -->
          <v-list-item
            v-if="$can('category_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardCategories' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-arrow-up </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Categories </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Companies -->
          <v-list-item
            v-if="$can('company_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardCompanies' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-marker </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Companies </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Contacts -->
          <v-list-item
            v-if="$can('contact_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardContacts' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-phone-log </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Contacts </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Credits -->
          <v-list-item
            v-if="$can('credit_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardCredits' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-account-cash </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Credits </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Credit Transactions -->
          <v-list-item
            v-if="$can('credit_transaction_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardCreditTransactions' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-account-credit-card </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Credit Transactions </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Documents -->
          <v-list-item
            v-if="$can('document_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardDocuments' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-file </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Documents </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Document Lines -->
          <v-list-item
            v-if="$can('document_line_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardDocumentLines' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-file-outline </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Document Lines </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Images -->
          <v-list-item
            v-if="$can('image_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardImages' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-image </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Images </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Invites -->
          <v-list-item
            v-if="$can('invite_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardInvites' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-multiple-plus </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Invites </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Password Resets -->
          <v-list-item
            v-if="$can('password_reset_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardPasswordResets' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-key </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Password Resets </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Permissions -->
          <v-list-item
            v-if="$can('permission_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardPermissions' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-cog-outline </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Permissions </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Roles -->
          <v-list-item
            v-if="$can('role_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardRoles' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-cog </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Roles </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Service Categories -->
          <v-list-item
            v-if="$can('service_category_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardServiceCategories' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-remove </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Service Categories </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Services -->
          <v-list-item
            v-if="$can('service_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardServices' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-remove-outline </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Services </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Service Details -->
          <v-list-item
            v-if="$can('service_detail_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardServiceDetails' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-text-outline </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Service Details </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Users -->
          <v-list-item
            v-if="$can('user_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardUsers' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-account </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Users </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Vendors -->
          <!-- <v-list-item
            v-if="$can('vendor_access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardVendors' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-folder-open-outline </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Vendors </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <!-- LogOut -->
          <v-list-item
            active-class="white secondary--text"
            @click="logOut()"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-logout </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Log Out </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Profile -->
          <v-list-item
            v-if="$can('access')"
            active-class="white secondary--text"
            :to="{ name: 'DashboardProfile' }"
            link
          >
            <v-list-item-icon>
              <v-icon> mdi-account </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Profile </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- dialogBuyCredits -->
    <v-dialog v-model="dialogBuyCredits" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Buy Credits</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formBuyCredits" lazy-validation>
              <v-row>
                <!-- Number Of Credits -->
                <v-col class="mt-n5" cols="12">
                  <v-text-field
                    v-model="numberOfCredits"
                    :rules="numberOfCreditsRules"
                    label="Number Of Credits"
                    type="number"
                    required
                  >
                    <template v-slot:label>
                      <p class="body-1">
                        Number Of Credits <span class="red--text">*</span>
                      </p>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="btn btn-primary white--text" @click="closeBuyCredits">
            Cancel
          </v-btn>
          <v-btn color="btn btn-primary white--text" @click="saveBuyCredits">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Overlay -->
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      overlay: false,

      dialogBuyCredits: false,

      drawer: false,
      group: null,

      // numberOfCredits
      numberOfCredits: "",
      numberOfCreditsRules: [
        (v) => !!v || "Required",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],
    };
  },

  watch: {
    // group() {
    //   this.drawer = false;
    // },
  },

  computed: {
    ...mapState({ auth: "auth" }),
    ...mapState(["dashboardSidebarMini", "dashboardSidebarDrawer"]),
    
    // showVerifyEmail
    showVerifyEmail() {
      return this.$store.getters['auth/getShowVerifyEmail'];
    }
  },

  methods: {
    ...mapActions({
      logOutUser: "auth/logOutUser",
    }),

    ...mapMutations({
      setDashboardSidebarDrawer: "setDashboardSidebarDrawer",
      setDashboardSidebarMini: "setDashboardSidebarMini",

      setUser: "auth/setUser",
      resetAuthState: "auth/resetState",
    }),

    // logOut
    logOut() {
      this.resetAuthState();

      this.$router.push("/login");
    },

    // changeDashboardSidebarMini
    changeDashboardSidebarMini() {
      this.setDashboardSidebarMini(!this.dashboardSidebarMini);
    },

    // changeDashboardSidebarDrawer
    changeDashboardSidebarDrawer() {
      this.setDashboardSidebarDrawer(!this.dashboardSidebarDrawer);
    },

    // saveBuyCredits
    saveBuyCredits() {
      this.validateFormBuyCredits();
    },

    // closeBuyCredits
    closeBuyCredits() {
      this.dialogBuyCredits = false;
    },

    // validateFormBuyCredits
    validateFormBuyCredits() {
      this.$refs.formBuyCredits.validate();

      if (this.$refs.formBuyCredits.validate()) {
        this.paymentRequest();
      }
    },

    // paymentRequest
    async paymentRequest() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        "payment/pay",
        {
          numberOfCredits: this.numberOfCredits,
        },
        "Payment Request Successfully!"
      );

      if (res.status == 200) {
        let data = res.data;

        this.closeBuyCredits();

        if ("url" in data && data.url != null) {
          window.location.href = data.url;
        }
      }

      this.overlay = false;
    },

    // createMyCompanyCredit
    async createMyCompanyCredit() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        `/credits/create-my-company-credit`,
        {},
        "Creted Credits Successfully!"
      );

      if (res.status == 200) {
        // getUserDetails()
        this.getUserDetails();
      }

      this.overlay = false;
    },

    // getUserDetails
    async getUserDetails() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("user-details");

      if (res.status == 200) {
        const data = res.data;

        this.setUser(data);
      }

      this.overlay = false;
    },

    // verifyEmail
    async verifyEmail() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        `request-verify-email`,
        {
          
        },
        "Please check your email for verification"
      );

      if (res.status == 200) {
        //
      } else if(res.status == 422) {
        // data
        let data = res.data;

        if(data && "message" in data && data.message === "Email Already Verified."){
          // getUserDetails
          this.getUserDetails();
        }
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// .nav-bar {
//   position: fixed;
//   top: 0;
//   right: 0;
//   left: 0;
//   z-index: 4;
//   margin: 0;
//   padding: auto;

//   background: $secondary;
//   backdrop-filter: blur(15px);

//   .bg-transparent {
//     background: transparent;
//   }

//   .heading-h6 {
//     line-height: 20px;
//   }
// }
</style>
